<template>
  <div>
    <!-- Footer -->
    <footer
      class="footer primary-back"
      itemtype="http://schema.org/WPFooter"
      itemscope
    >
      <div class="container">
        <div class="footer__widgets">
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="widget widget-about-us text-left text-sm-center">                
                <a
                  href="mailto:info@ignitefacilities.co.uk"
                  class="widget-about-us__email"
                  >info@ignitefacilities.co.uk</a
                >
                <a href="tel:+441782706984" class="widget-about-us__phone"
                  >01782 706984</a
                >
                
              </div>
            </div>

            <div class="col-lg-4 col-md-6 text-center">
              <img
                src="img/ignite/branding/ignite-logo.png"
                style="width:22rem;"
              />
              <div class="text-center">
                <div class="socials mt-32">
                  <a
                    href="https://www.linkedin.com/company/ignite-facilities-ltd"
                    class="social social-google-plus"
                    aria-label="linkedin"
                    title="linkedin"
                    target="_blank"
                  >
                    <i class="ui-linkedin"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/Ignite-Facilities-LTD-1836503426580399/"
                    class="social social-facebook"
                    aria-label="facebook"
                    title="facebook"
                    target="_blank"
                  >
                    <i class="ui-facebook"></i>
                  </a>
                </div>
              </div>
            </div>


            <div class="col-lg-4 col-md-6">
              <div class="widget widget-about-us__text text-left text-sm-center">
                <a href="https://www.google.co.uk/maps/place/Ignite+Facilities+Ltd/@53.0178261,-2.1680774,15z/data=!4m6!3m5!1s0x487a418a9f2787bd:0x4449a6cd62e1e99a!8m2!3d53.0178261!4d-2.1680774!16s%2Fg%2F11g6jt57kh?entry=ttu" target="_blank" class="widget-about-us__text">
                  Ignite Facilities Ltd
                  <br>
                  Unit 7a Oldham Street
                  <br>
                  Joiners Square Industrial Estate
                  <br>
                  Stoke on Trent
                  <br>
                  ST1 3EY
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer__bottom">
        <div class="container copyright-cont">
          <div class="copyright-wrap text-center row">

            <div class="col-lg-4 col-md-6 text-center">
              <span class="copyright">
                <router-link to="Privacy" class="copyright">
                  <span>Privacy Policy</span>
                </router-link>
              </span>
            </div>

            <div class="col-lg-4 col-md-6 text-center">
              <span class="text-center block mt-4">
              <a href="https://cyberkiln.com/" target="_blank">
                Crafted by
                <br />
                <img
                  class="cyberkiln"
                  src="@/img/cyberkiln.svg"
                />
              </a>
            </span>
            </div>

            <div class="col-lg-4 col-md-6 text-center">
              <span class="copyright">
                <div class="copyright">
                  <div class="text-center">
                    <div class="socials ">
                        <img class="footer-logo" src="@/img/footer-logos/safe.jpg"/>                       
                        <img class="footer-logo" src="@/img/footer-logos/cscs.jpg"/>                      
                        <img class="footer-logo" src="@/img/footer-logos/gas-cert.jpg"/>
                        <img class="footer-logo" src="@/img/footer-logos/iosh.jpg"/>
                        <img class="footer-logo" src="@/img/footer-logos/IPAF.jpg"/>
                        <img class="footer-logo" src="@/img/footer-logos/oftec.jpg"/>
                    </div>
                  </div>
                </div>
              </span>
            </div>

          </div>
          <a href="https://www.phoenix-fc.co.uk/finance_landing?b=685D4523543346A1&t=71C61C54909642DF8B21E139" target="_blank">            
            <img
              class="phoenix"
              src="@/img/phoenix.png"
            />
            Ignite Facilities Ltd is an Introducer Appointed Representative (Financial Services Register No. 1031375) of Phoenix Financial Consultants Limited (Phoenix). Phoenix is a credit broker, not a lender. Phoenix is authorised and regulated by the Financial Conduct Authority (FRN: 539195), and offers finance from its panel of lenders. All finance subject to status and credit checks.
          </a>

        </div>
      </div>
    </footer>
    <!-- end footer -->
    <!-- <div class="footer-placeholder" id="contact"></div> -->
  </div>
</template>

<script>
import modal from "@/components/ContactModal.vue";

export default {
  name: "app",
  components: {
    modal // eslint-disable-line vue/no-unused-components
  },
  data() {
    return {
      isModalVisible: false,
      privacyPolicy: "/documents/HS Privacy Policy.pdf"
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    }
  }
};
</script>


